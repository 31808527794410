import React, { Suspense } from 'react';
import routes from '../../routes'
import {Route, Routes} from 'react-router-dom'


const AppBody =()=> {
  return (<>
      <Suspense fallback={<div className='fallbackSpinner'/>}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
        </Routes>
      </Suspense>    
    </>);
}

export default React.memo(AppBody);
