import React, { useState } from 'react';
import productImag from "../../assets/images/no-image.png";
import productImag2 from "../../assets/images/product2.jpg";
import "./product-listing.scss";
import { IoHeartOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai";


const Product=({elem})=> {
  const [newBigImg, setBigImg] = useState('');
  const [active, setActive] = useState(0);
  const [discount, setDiscount] = useState(0);

  const changeBigImage = (prms, i)=>{
    setBigImg(prms);
    setActive(i);
  }
 
  useState(()=>{ 
    setDiscount(Math.round(((elem.base_price - elem.buy_now_price)/elem.base_price)*100));
    console.log(process.env.REACT_APP_IMAGE_PATH+elem.cover_image);
  }, [])
  return (<>
    <div className='each_product'>
        <div className='eachProductImage'>
            {discount>0 && <div className={'pBadge ' + 'discount'}>-{discount}%</div>}
            <img src={elem.cover_image?process.env.REACT_APP_IMAGE_PATH+elem.cover_image:productImag} alt=""/>
        </div>
        <div className='eachProductText'>  
            <div className='favouriteStatus'>
                <IoHeartOutline/>
            </div>          
            <h6>{elem.category_name}</h6>
            <h2>{elem.name}</h2>
            <div className='moreFetureImgThumb'>
                <ul>
                    <li className={active===0?"active":""} onClick={()=>{changeBigImage(process.env.REACT_APP_IMAGE_PATH+elem.cover_image, 0)}}>
                        <img src={process.env.REACT_APP_IMAGE_PATH+elem.cover_image} alt=""/>
                    </li>         
                </ul>
            </div>
            <h3>${elem.buy_now_price}</h3>

            <div className='ratingOrderWrap'>
                <div className='rating'>
                    <ul>
                        {elem.total_rating>=1 && <li><AiFillStar/></li>}
                        {elem.total_rating>=2 && <li><AiFillStar/></li>}
                        {elem.total_rating>=3 && <li><AiFillStar/></li>}
                        {elem.total_rating>=4 && <li><AiFillStar/></li>}
                        {elem.total_rating=5 && <li><AiFillStar/></li>}
                    </ul>
                </div>
                <div className='orderCount'>
                    {elem.total_order} Orders
                </div>
            </div>

            
        </div>
        
    </div>
  </>); 
}

export default Product;
