import React, { useState } from 'react';
import recentImg from "../../assets/images/shirt.jpg";
import recentImg2 from "../../assets/images/shirt2.jpg";
import recentImg3 from "../../assets/images/shirt3.jpg";
import "./recently-viewed.scss";
import { IoHeartOutline} from "react-icons/io5";


function RecentlyViewed() {
  const [newBigImg, setBigImg] = useState('');
  const [active, setActive] = useState(0);
  const changeBigImage = (prms, i)=>{
    setBigImg(prms);
    setActive(i);
  }
  return (<>
    <div className='each_RecentlyView'>
        <div className='eachRecentlyViewImg'>
            <div className='favouriteStatus'>
                <IoHeartOutline/>
            </div>    
            <img src={newBigImg?newBigImg:recentImg} alt=""/>
        </div>
        <div  className='eachRecentlyViewText'>  
            <div className='suitColorCombination'>
                <ul>
                    <li className={active===0?"active":""} onClick={()=>{changeBigImage(recentImg, 0)}}><span style={{background: "#08166b"}}></span></li>
                    <li className={active===1?"active":""} onClick={()=>{changeBigImage(recentImg3, 1)}}><span style={{background: "#383737"}}></span></li>
                    <li className={active===2?"active":""} onClick={()=>{changeBigImage(recentImg2, 2)}}><span style={{background: "#e8512b"}}></span></li>
                </ul>
            </div> 
            <h2>Customized Watches</h2>            
            <h3>$230.00</h3>            
        </div>
        
    </div>
  </>); 
}

export default React.memo(RecentlyViewed);
