import React, { useState, useEffect} from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import "./header.scss";
import logo from "../../assets/images/logo.png";
import { FaHeart, FaSearch } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';



function Header() {
  const [menuToggle, SetMenuToggle] = useState(false);
  const [category, setCategory] = useState([]);

  const toggleMenu = ()=>{  
      SetMenuToggle(!menuToggle);    
  }
  const  getAllCategory =  async()=>{
    await fetch(process.env.REACT_APP_API_URL+'category-listing', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((r)=> r.json())
        .then((data)=>{                    
            setCategory(data);
        });
  }

  useEffect(()=>{
    getAllCategory();
  }, [])

  return (<header className='siteHeader'>
    <section className='topHeader'>
      <div className='container-fluid'>
        <Row  className="justify-content-end">
          <Col sm="auto">
            <div className='d-flex justify-content-between topHeaderright'>
              <ul>
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Ship to
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Action</Dropdown.Item>
                      <Dropdown.Item href="#">Another action</Dropdown.Item>
                      <Dropdown.Item href="#">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>             
                  <a href='#'>Sell</a>
                </li>
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      My Account
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Action</Dropdown.Item>
                      <Dropdown.Item href="#">Another action</Dropdown.Item>
                      <Dropdown.Item href="#">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>                
                </li>
              </ul>
              <ul>
                <li>
                  <a href='#'>
                    <img src={require("../../assets/images/icon/bell.png")}/>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <img src={require("../../assets/images/icon/cart.png")}/>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <img src={require("../../assets/images/icon/usa.png")}/>
                  </a>
                </li>
              </ul>
            </div>
          </Col>          
        </Row>
      </div>  
    </section>
    <section className='middleHeader'>
      <div className='container-fluid'>
        <Row className='align-items-center'>
          <Col xs={'auto'}>
            <div className='logo_Wrap'>
              <img src={logo}/>
            </div>
          </Col>
          <Col >
            <div className='searchWrapper'>
              <select className='searchSelect'>
                <option>All Categories</option>
                {category.map((i, k)=>(
                  <option value={i.category_id} key={k}>{i.name}</option>
                ))}
              </select>
              <div className='searchGroup'>
                <input placeholder='Search...' className='headerSearch'/>
                <Button  className='headerSearchBtn'><FaSearch /></Button> 
              </div>
            </div>
            <div className='searchMobileBtn'>
              <IoSearchOutline/>
            </div>
          </Col>
          <Col xs={'auto'}>
            <div className='text-end signInlinkInfo'>
              <a href='#'>Sign In</a> Or  <a href='#'>Register</a>
            </div>
            <div className='mobile-menu-btn' onClick={toggleMenu}>
                <span className='toggler-icon'></span>
                <span className='toggler-icon'></span>
                <span className='toggler-icon'></span>
            </div>
          </Col>
        </Row>
      </div>
    </section>
    <section className='bottomHeader' style={{display: menuToggle?"block":"none"}}>
      <div className='container-fluid'>
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/category'>Category</Link></li>
          <li><Link to='/saved'><FaHeart/> Saved</Link></li>
          {category.map((i, k)=>(
            <li key={k}><Link to={"/category/"+i.category_id}>{i.name}</Link></li>
          ))}
        </ul>
      </div>
    </section>
  </header>);
}


export default React.memo(Header)
