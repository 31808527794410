import React, {Suspense} from 'react';
import DefaultLayout from './layout/DefaultLayout';
import { HashRouter, Route, Routes} from 'react-router-dom';
import './App.scss';


function App() {
  return (
    <div className="App">
      <HashRouter>
        <Suspense fallback={<div/>}>
          <Routes>            
            <Route path="*" name="Home" element={<DefaultLayout/>} />
          </Routes>
        </Suspense>
      </HashRouter>
      
    </div>
  );
}

export default App;
