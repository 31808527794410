import React from 'react';
import { Footer, Header, AppBody } from '../components/component.export';

function DefaultLayout() {
  return (<>
        <Header/>
        <AppBody/>
        <Footer/>
        </>);
}

export default DefaultLayout;
